import { Injectable, WritableSignal, signal } from '@angular/core';
import { Notification } from '../../models/notification.interface';

@Injectable({
    providedIn: 'root',
})
export class CoreService {
    // Notification
    public notifications: WritableSignal<Notification[]> = signal([]);

    public displayLoader: WritableSignal<boolean> = signal(false);

    // Date
    private currentDate!: Date;
    private readonly currentDateInterval;

    constructor() {
        this.currentDateInterval = setInterval(() => {
            this.setDate(new Date());
        }, 1000);
    }

    // Date
    public setDate(value: Date): void {
        this.currentDate = value;
    }

    public getCurrentDate(): Date {
        return this.currentDate;
    }
}
