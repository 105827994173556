
export const environment = {
   backend: 'undefined',
   production: true,
   appUrl: 'https://avd-extractor.flights-footprint.com',
   backendApi: 'https://avd-extractor-back.flights-footprint.com',
   databricksToken: 'undefined',
   msalClientId: '32bda4ae-dbe2-4e30-9423-106d753bece4',
   msalSusi: 'B2C_1A_DEMO_SIGNUP_SIGNIN_TOTP',
   msalEditProfile: 'B2C_1_edit_profile',
   msalResetPassword: 'B2C_1A_DEMO_PASSWORDRESET_TOTP',
   msalTenant: 'flightsfootprint',
   msalScope: '3142f398-47b1-4c3d-9c6a-04ac2cbd1a18/default',
   appInsightsConnectionString: 'InstrumentationKey=2c384fe8-1454-4aff-aed4-3800b1da80c7;IngestionEndpoint=https://westeurope-5.in.applicationinsights.azure.com/;LiveEndpoint=https://westeurope.livediagnostics.monitor.azure.com/'
};
