import { Component } from '@angular/core';
import { CoreService } from '../../services/core/core.service';
import { NotificationComponent } from './components/notification/notification.component';

@Component({
    selector: 'notification-panel',
    standalone: true,
    imports: [NotificationComponent],
    templateUrl: './notification-panel.component.html',
    styleUrl: './notification-panel.component.scss',
})
export class NotificationPanelComponent {
    constructor(public readonly coreService: CoreService) {}
}
