<form [formGroup]="form" (ngSubmit)="onSubmit()">
    <div class="email form-item">
        <qtm-typography fontSize="6" fontWeight="medium"> Emails *</qtm-typography>
        <mat-form-field appearance="outline">
            <mat-chip-grid #emailChipGrid aria-label="Enter email" formControlName="emailList">
                @for (email of emailList(); track email) {
                    <mat-chip-row (removed)="removeKeyword(email, emailList)">
                        {{ email }}
                        <mat-icon matChipRemove>cancel</mat-icon>
                    </mat-chip-row>
                }
                <input
                    placeholder="x.x@thalesgroup.com"
                    [matChipInputFor]="emailChipGrid"
                    (matChipInputTokenEnd)="add($event, emailList)"
                    matChipInputAddOnBlur="true"
                />
            </mat-chip-grid>
            @if (getErrorMessage("emailList") !== "") {
                <mat-error>{{ getErrorMessage("emailList") }}</mat-error>
            }
        </mat-form-field>
    </div>
    <div class="start-date form-item">
        <qtm-typography fontSize="6" fontWeight="medium"> Start date (included)*</qtm-typography>
        <mat-form-field appearance="outline">
            <input
                matInput
                placeholder="02/06/2024"
                [matDatepicker]="startDatePicker"
                required
                formControlName="startDate"
                (dateChange)="form.get('endDate')?.updateValueAndValidity()"
            />
            <mat-hint>DD/MM/YYYY</mat-hint>
            <mat-datepicker-toggle matIconSuffix [for]="startDatePicker"></mat-datepicker-toggle>
            <mat-datepicker #startDatePicker></mat-datepicker>
            @if (getErrorMessage("startDate") !== "") {
                <mat-error>{{ getErrorMessage("startDate") }}</mat-error>
            }
        </mat-form-field>
    </div>
    <div class="end-date form-item">
        <qtm-typography fontSize="6" fontWeight="medium"> End date (included)*</qtm-typography>
        <mat-form-field appearance="outline">
            <input
                matInput
                placeholder="02/06/2024"
                [matDatepicker]="endDatePicker"
                required
                formControlName="endDate"
                (dateChange)="form.get('startDate')?.updateValueAndValidity()"
            />
            <mat-hint>DD/MM/YYYY</mat-hint>
            <mat-datepicker-toggle matIconSuffix [for]="endDatePicker"></mat-datepicker-toggle>
            <mat-datepicker #endDatePicker></mat-datepicker>
            @if (getErrorMessage("endDate") !== "") {
                <mat-error>{{ getErrorMessage("endDate") }}</mat-error>
            }
        </mat-form-field>
    </div>
    <div class="airline form-item">
        <qtm-typography fontSize="6" fontWeight="medium"> Airlines</qtm-typography>
        <mat-form-field appearance="outline">
            <mat-chip-grid #airlineChipGrid aria-label="Enter airline" formControlName="airlineIcaoList">
                @for (airline of airlineIcaoList(); track airline) {
                    <mat-chip-row (removed)="removeKeyword(airline, airlineIcaoList)">
                        {{ airline }}
                        <mat-icon matChipRemove>cancel</mat-icon>
                    </mat-chip-row>
                }
                <input
                    placeholder="AFR"
                    [matChipInputFor]="airlineChipGrid"
                    (matChipInputTokenEnd)="add($event, airlineIcaoList)"
                    matChipInputAddOnBlur="true"
                />
            </mat-chip-grid>
            @if (getErrorMessage("airlineIcaoList") !== "") {
                <mat-error>{{ getErrorMessage("airlineIcaoList") }}</mat-error>
            }
        </mat-form-field>
    </div>
    <div class="start-airport form-item">
        <qtm-typography fontSize="6" fontWeight="medium">Departure Airports</qtm-typography>
        <mat-form-field appearance="outline">
            <mat-chip-grid #startAirportsChipGrid aria-label="Enter start airport" formControlName="adepIataList">
                @for (startAirport of adepIataList(); track startAirport) {
                    <mat-chip-row (removed)="removeKeyword(startAirport, adepIataList)">
                        {{ startAirport | uppercase }}
                        <mat-icon matChipRemove>cancel</mat-icon>
                    </mat-chip-row>
                }
                <input
                    placeholder="TLS"
                    [matChipInputFor]="startAirportsChipGrid"
                    (matChipInputTokenEnd)="add($event, adepIataList)"
                    matChipInputAddOnBlur="true"
                />
            </mat-chip-grid>
            @if (getErrorMessage("adepIataList") !== "") {
                <mat-error>{{ getErrorMessage("adepIataList") }}</mat-error>
            }
        </mat-form-field>
    </div>
    <div class="end-airport form-item">
        <qtm-typography fontSize="6" fontWeight="medium">Arrival Airports</qtm-typography>
        <mat-form-field appearance="outline">
            <mat-chip-grid #endAirportsChipGrid aria-label="Enter end airport" formControlName="adesIataList">
                @for (endAirport of adesIataList(); track endAirport) {
                    <mat-chip-row (removed)="removeKeyword(endAirport, adesIataList)">
                        {{ endAirport | uppercase }}
                        <mat-icon matChipRemove>cancel</mat-icon>
                    </mat-chip-row>
                }
                <input
                    placeholder="CDG"
                    [matChipInputFor]="endAirportsChipGrid"
                    (matChipInputTokenEnd)="add($event, adesIataList)"
                    matChipInputAddOnBlur="true"
                />
            </mat-chip-grid>
            @if (getErrorMessage("adesIataList") !== "") {
                <mat-error>{{ getErrorMessage("adesIataList") }}</mat-error>
            }
        </mat-form-field>
    </div>
    <div class="aircraft-type form-item">
        <qtm-typography fontSize="6" fontWeight="medium">Aircraft types</qtm-typography>
        <mat-form-field appearance="outline">
            <mat-chip-grid #aircraftTypeListChipGrid aria-label="Enter aircraft types" formControlName="aircraftTypeList">
                @for (aircraftType of aircraftTypeList(); track aircraftType) {
                    <mat-chip-row (removed)="removeKeyword(aircraftType, aircraftTypeList)">
                        {{ aircraftType | uppercase }}
                        <mat-icon matChipRemove>cancel</mat-icon>
                    </mat-chip-row>
                }
                <input
                    placeholder="A320"
                    [matChipInputFor]="aircraftTypeListChipGrid"
                    (matChipInputTokenEnd)="add($event, aircraftTypeList)"
                    matChipInputAddOnBlur="true"
                />
            </mat-chip-grid>
        </mat-form-field>
    </div>
    <div class="flight-number form-item">
        <qtm-typography fontSize="6" fontWeight="medium">Flight numbers</qtm-typography>
        <mat-form-field appearance="outline">
            <mat-chip-grid #flightNumberListChipGrid aria-label="Enter aircraft types" formControlName="flightNumberList">
                @for (flightNumber of flightNumberList(); track flightNumber) {
                    <mat-chip-row (removed)="removeKeyword(flightNumber, flightNumberList)">
                        {{ flightNumber | uppercase }}
                        <mat-icon matChipRemove>cancel</mat-icon>
                    </mat-chip-row>
                }
                <input
                    placeholder="AF6101"
                    [matChipInputFor]="flightNumberListChipGrid"
                    (matChipInputTokenEnd)="add($event, flightNumberList)"
                    matChipInputAddOnBlur="true"
                />
            </mat-chip-grid>
        </mat-form-field>
    </div>
    <div class="callsign form-item">
        <qtm-typography fontSize="6" fontWeight="medium">Callsigns</qtm-typography>
        <mat-form-field appearance="outline">
            <mat-chip-grid #callsignListChipGrid aria-label="Enter aircraft types" formControlName="callsignList">
                @for (callSign of callsignList(); track callSign) {
                    <mat-chip-row (removed)="removeKeyword(callSign, callsignList)">
                        {{ callSign | uppercase }}
                        <mat-icon matChipRemove>cancel</mat-icon>
                    </mat-chip-row>
                }
                <input
                    placeholder="AFR6101"
                    [matChipInputFor]="callsignListChipGrid"
                    (matChipInputTokenEnd)="add($event, callsignList)"
                    matChipInputAddOnBlur="true"
                />
            </mat-chip-grid>
        </mat-form-field>
    </div>

    <mat-checkbox class="extract-return" [checked]="extractReturn" formControlName="extractReturn">
        Extract return <mat-icon matTooltip="Extract flights from the inverse city pair">info</mat-icon>
    </mat-checkbox>
    <mat-checkbox class="insert-in-ffp" [checked]="insertInFfp" formControlName="insertInFfp">Insert in Flights Footprint</mat-checkbox>
    <button mat-button color="primary" class="reset" (click)="resetForm()" type="button">Reset</button>
    <button mat-flat-button color="primary" type="submit" class="submit" [disabled]="!form.valid">Submit</button>
</form>
