import { Inject, Injectable } from '@angular/core';
import { Location } from '@angular/common';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { MSAL_GUARD_CONFIG, MsalGuardConfiguration, MsalService } from '@azure/msal-angular';
import { catchError, concatMap, map } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { AuthService } from './services/auth/auth.service';

@Injectable({
    providedIn: 'root',
})
export class AuthGuard {
    constructor(
        protected readonly _router: Router,
        private _authService: AuthService,
        private _location: Location,
        @Inject(MSAL_GUARD_CONFIG)
        private _msalGuardConfig: MsalGuardConfiguration,
        private _msalService: MsalService,
    ) {}

    getDestinationUrl(path: string): string {
        // Absolute base url for the application (default to origin if base element not present)
        const baseElements = document.getElementsByTagName('base');
        const baseUrl = this._location.normalize(baseElements.length ? baseElements[0].href : window.location.origin);

        // Path of page (including hash, if using hash routing)
        const pathUrl = this._location.prepareExternalUrl(path);

        // Hash location strategy
        if (pathUrl.startsWith('#')) {
            return `${baseUrl}/${pathUrl}`;
        }

        /*
         * If using path location strategy, pathUrl will include the relative portion of the base path (e.g. /base/page).
         * Since baseUrl also includes /base, can just concatenate baseUrl + path
         */
        return `${baseUrl}${path}`;
    }

    // eslint-disable-next-line unused-imports/no-unused-vars
    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> {
        return this._msalService.handleRedirectObservable().pipe(
            concatMap(() => {
                if (!this._authService.isLoggedIn) {
                    if (state) {
                        const redirectStartPage = this.getDestinationUrl(state.url);
                        return this._authService.signIn(redirectStartPage).pipe(map(() => false));
                    }
                    return of(false);
                }

                return of(true);
            }),
            catchError(() => {
                this._router.navigate([this._msalGuardConfig.loginFailedRoute]).then();
                return of(false);
            }),
        );
    }
}
