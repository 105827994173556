import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';

@Injectable({
    providedIn: 'root',
})
export class ExtractorService {
    constructor(private httpClient: HttpClient) {}

    public postExtractRequest(data: FormData): Observable<{ message: string }> {
        return this.httpClient.post<{ message: string }>(environment.backendApi + '/api/extract', data);
    }
}
