import { AbstractControl, FormGroup, ValidationErrors, ValidatorFn } from '@angular/forms';

export function atLeastOneChipValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
        const chips = control.value;
        if (chips && chips.length > 0) {
            return null;
        }
        return { atLeastOneChip: true };
    };
}

export function validFormat(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
        const iatas: string[] = control.value;
        if (iatas.length > 0 && iatas.every((iata) => iata.length === 3)) {
            return null;
        }

        if (iatas.length == 0) {
            return null;
        }

        return { validFormat: true };
    };
}

export function containsEmail(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
        const emails: string[] = control.value;
        if (emails && emails.every((email) => email.includes('@'))) {
            return null;
        }
        return { containsEmail: true };
    };
}

export function startDateValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
        const formGroup = control.parent as FormGroup;
        if (!formGroup) return null; // If no form group, return null
        const endDate = formGroup.controls['endDate'].value;

        if (control.value && endDate && endDate !== '' && control.value > endDate) {
            return { startDateInvalid: true };
        }
        return null;
    };
}

export function endDateValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
        const formGroup = control.parent as FormGroup;
        if (!formGroup) return null; // If no form group, return null
        const startDate = formGroup.controls['startDate'].value;

        if (control.value && startDate && startDate !== '' && control.value < startDate) {
            return { endDateInvalid: true };
        }
        return null;
    };
}

export function notFutureDateValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
        const date = control.value;
        const currentDate = new Date();

        if (date && new Date(date) > currentDate) {
            return { futureDateInvalid: true };
        }
        return null;
    };
}

export function atLeastOneFieldFilledValidator(): ValidatorFn {
    return (formGroup: AbstractControl): ValidationErrors | null => {
        const fieldKeys = ['airlineIcaoList', 'adepIataList', 'adesIataList', 'aircraftTypeList', 'flightNumberList', 'callsignList'];

        const isAtLeastOneFilled = fieldKeys.some((key) => formGroup.get(key)?.value.length > 0);

        if (!isAtLeastOneFilled) {
            return { atLeastOneFieldRequired: true };
        }
        return null;
    };
}
