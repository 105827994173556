import { Component } from '@angular/core';
import { environment } from 'src/environments/environment';
import { ApplicationInsights, DistributedTracingModes } from '@microsoft/applicationinsights-web';
import { AuthService } from './services/auth/auth.service';
import { CoreService } from './services/core/core.service';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
})
export class AppComponent {
    constructor(
        public authService: AuthService,
        public coreService: CoreService,
    ) {
        if (this.isProduction) {
            const appInsights = new ApplicationInsights({
                config: {
                    connectionString: environment.appInsightsConnectionString,
                    distributedTracingMode: DistributedTracingModes.W3C,
                    disableFetchTracking: true,
                    enableCorsCorrelation: true,
                    enableAutoRouteTracking: true,
                    enableRequestHeaderTracking: true,
                    enableResponseHeaderTracking: true,
                    autoTrackPageVisitTime: true,
                    correlationHeaderExcludedDomains: ['*.queue.core.windows.net'],
                },
            });
            appInsights.loadAppInsights();

            // Google Tag Manager / PiwikPro
            const head = document.head;
            const script = document.createElement('script');
            script.type = 'text/javascript';
            script.innerHTML = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
                    new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
                j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
                'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
            })(window,document,'script','dataLayer','GTM-T3XTWHB');`;
            head.insertBefore(script, head.children[0]);
        }
    }

    get isProduction(): boolean {
        return environment.appUrl === 'https://avd-extractor.flights-footprint.com';
    }
}
