import { Component, OnInit, WritableSignal, signal } from '@angular/core';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatButton } from '@angular/material/button';
import { MatChipGrid, MatChipInput, MatChipInputEvent, MatChipRemove, MatChipRow } from '@angular/material/chips';
import { MatDatepicker, MatDatepickerInput, MatDatepickerToggle } from '@angular/material/datepicker';
import { MatError, MatFormField, MatHint, MatLabel, MatSuffix } from '@angular/material/form-field';
import { MatIcon } from '@angular/material/icon';
import { MatInput } from '@angular/material/input';
import { AuthService } from '../../services/auth/auth.service';
import { MatCheckbox } from '@angular/material/checkbox';
import { MatTooltip } from '@angular/material/tooltip';
import {
    atLeastOneChipValidator,
    atLeastOneFieldFilledValidator,
    containsEmail,
    endDateValidator,
    notFutureDateValidator,
    startDateValidator,
    validFormat,
} from './validators/custom.validator';
import { QtmComponentLibraryModule } from '@qtm/angular';
import { ExtractorService } from '../../services/extractor/extractor.service';
import { Subject, switchMap } from 'rxjs';
import { FormatDate } from '../../pipes/format-data.pipe';
import { CoreService } from '../../services/core/core.service';
import { UpperCasePipe } from '@angular/common';

@Component({
    selector: 'extractor-form',
    standalone: true,
    imports: [
        FormsModule,
        MatButton,
        MatChipGrid,
        MatChipInput,
        MatChipRow,
        MatDatepicker,
        MatDatepickerInput,
        MatDatepickerToggle,
        MatFormField,
        MatHint,
        MatIcon,
        MatInput,
        MatLabel,
        MatSuffix,
        MatError,
        ReactiveFormsModule,
        MatCheckbox,
        MatTooltip,
        QtmComponentLibraryModule,
        MatChipRemove,
        UpperCasePipe,
    ],
    providers: [AuthService, FormatDate],
    templateUrl: './extractor-form.component.html',
    styleUrl: './extractor-form.component.scss',
})
export class ExtractorFormComponent implements OnInit {
    readonly emailList: WritableSignal<string[]> = signal([]);
    readonly airlineIcaoList: WritableSignal<string[]> = signal([]);
    readonly adepIataList: WritableSignal<string[]> = signal([]);
    readonly adesIataList: WritableSignal<string[]> = signal([]);
    readonly aircraftTypeList: WritableSignal<string[]> = signal([]);
    readonly flightNumberList: WritableSignal<string[]> = signal([]);
    readonly callsignList: WritableSignal<string[]> = signal([]);
    readonly insertInFfp: WritableSignal<boolean> = signal(false);
    readonly extractReturn: WritableSignal<boolean> = signal(false);

    form: FormGroup = new FormGroup(
        {
            emailList: new FormControl('', [atLeastOneChipValidator(), containsEmail()]),
            startDate: new FormControl('', [Validators.required, startDateValidator(), notFutureDateValidator()]),
            endDate: new FormControl('', [Validators.required, endDateValidator(), notFutureDateValidator()]),
            airlineIcaoList: new FormControl('', [validFormat()]),
            adepIataList: new FormControl('', [validFormat()]),
            adesIataList: new FormControl('', [validFormat()]),
            aircraftTypeList: new FormControl(''),
            flightNumberList: new FormControl(''),
            callsignList: new FormControl(''),
            insertInFfp: new FormControl(false),
            extractReturn: new FormControl(false),
        },
        atLeastOneFieldFilledValidator(),
    );

    readonly initialForm = this.form;
    private pendingAircraftTypeRequest: Subject<void> = new Subject<void>();

    constructor(
        private _extractorService: ExtractorService,
        private _formatDate: FormatDate,
        private _coreService: CoreService,
    ) {}

    removeKeyword(keyword: string, list: WritableSignal<string[]>) {
        list.update((keywords) => {
            const index = keywords.indexOf(keyword);
            if (index < 0) {
                return keywords;
            }

            keywords.splice(index, 1);

            return [...keywords];
        });
    }

    add(event: MatChipInputEvent, list: WritableSignal<string[]>): void {
        const value = (event.value || '').trim();

        // Add our keyword
        if (value) {
            list.update((keywords) => [...keywords, value]);
        }

        // Clear the input value
        event.chipInput!.clear();
    }

    getErrorMessage(formItem: string): string {
        if (this.form.controls[formItem].hasError('validFormat') && formItem === 'airlineIcaoList') {
            return 'Airline ICAO expected';
        } else if (this.form.controls[formItem].hasError('validFormat') && (formItem === 'adepIataList' || formItem === 'adesIataList')) {
            return 'Airport IATA expected';
        } else if (this.form.controls[formItem].hasError('atLeastOneChip')) {
            return 'Field required';
        } else if (this.form.controls[formItem].hasError('startDateInvalid')) {
            return "Invalid start date : can't be greater than the end date ";
        } else if (this.form.controls[formItem].hasError('endDateInvalid')) {
            return "Invalid end date : can't be smaller than the start date ";
        } else if (this.form.controls[formItem].hasError('containsEmail')) {
            return 'Email malformed';
        } else if (this.form.controls[formItem].hasError('futureDateInvalid')) {
            return "Date can't be in the future";
        } else {
            return '';
        }
    }

    private _handleRequest(): void {
        this.pendingAircraftTypeRequest = new Subject<void>();
        this.pendingAircraftTypeRequest
            .pipe(
                switchMap(() => {
                    return this._extractorService.postExtractRequest(this.form.value);
                }),
            )
            .subscribe({
                next: (results) => {
                    this._coreService.displayLoader.set(false);
                    if (results.message === 'Processing started') {
                        this._coreService.notifications.update((values) => {
                            return [
                                ...values,
                                {
                                    title: 'Extraction request submitted! ',
                                    description:
                                        'The requested extraction process has begun! <br>\n' +
                                        'You will be informed when it is finished by an email sent to the email(s) provided.',
                                    icon: 'info',
                                    lifeDuration: 'infinite',
                                    index: 0,
                                    type: 'INFO',
                                },
                            ];
                        });
                    } else if (results.message === 'Already been extracted, check your emails') {
                        this._coreService.notifications.update((values) => {
                            return [
                                ...values,
                                {
                                    title: 'Extraction already computed! ',
                                    description: 'The requested extraction has already been extracted! <br> Please check your emails !',
                                    icon: 'warning',
                                    lifeDuration: 'infinite',
                                    index: 0,
                                    type: 'INFO',
                                },
                            ];
                        });
                    } else if (results.message === 'Already running, please wait') {
                        this._coreService.notifications.update((values) => {
                            return [
                                ...values,
                                {
                                    title: results.message + '!',
                                    description:
                                        'The requested extraction is already running! <br>' +
                                        'You will be informed when it is finished by an email sent to the email(s) provided.',
                                    icon: 'warning',
                                    lifeDuration: 'infinite',
                                    index: 0,
                                    type: 'WARNING',
                                },
                            ];
                        });
                    }
                },
                error: (error) => {
                    console.error(error);
                    this._coreService.displayLoader.set(false);
                    this._coreService.notifications.update((values) => {
                        return [
                            ...values,
                            {
                                title: 'An error occurred while sending your request !',
                                description: 'Please refresh, check that all mandatory fields are filled and try again.',
                                icon: 'warning',
                                lifeDuration: 'infinite',
                                index: 0,
                                type: 'ERROR',
                            },
                        ];
                    });
                },
            });
    }

    onSubmit(): void {
        const formData = this.form.value;
        Object.entries(formData).forEach(([key, value]) => {
            if (key !== 'startDate' && key !== 'endDate') {
                if (value === '') {
                    formData[key] = [];
                }
            }
        });
        formData.startDate = this._formatDate.transform(formData.startDate, 'yyyy-mm-dd');
        formData.endDate = this._formatDate.transform(formData.endDate, 'yyyy-mm-dd');
        this._coreService.displayLoader.set(true);
        this.pendingAircraftTypeRequest.next();
    }

    ngOnInit(): void {
        this._handleRequest();
    }

    resetForm(): void {
        this.form = this.initialForm;
        this.emailList.update(() => []);
        this.airlineIcaoList.update(() => []);
        this.adepIataList.update(() => []);
        this.adesIataList.update(() => []);
        this.aircraftTypeList.update(() => []);
        this.flightNumberList.update(() => []);
        this.callsignList.update(() => []);
        this.insertInFfp.update(() => false);
        this.extractReturn.update(() => false);
    }
}
