import { Component } from '@angular/core';
import { CoreService } from '../../services/core/core.service';
import { QtmComponentLibraryModule } from '@qtm/angular';
import { FormatFullDate, FormatFullTime } from '../../pipes/format-data.pipe';
import { AuthService } from '../../services/auth/auth.service';
import { MatIcon } from '@angular/material/icon';
import { MatButton } from '@angular/material/button';

@Component({
    selector: 'top-bar',
    templateUrl: './top-bar.component.html',
    styleUrls: ['./top-bar.component.scss'],
    standalone: true,
    imports: [QtmComponentLibraryModule, FormatFullTime, FormatFullDate, MatIcon, MatButton],
})
export class TopBarComponent {
    constructor(
        public coreService: CoreService,
        public authService: AuthService,
    ) {}
}
