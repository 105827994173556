import { BrowserModule } from '@angular/platform-browser';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { environment } from '../environments/environment';
import { QtmComponentLibraryModule } from '@qtm/angular';
import { IPublicClientApplication, InteractionType, PublicClientApplication } from '@azure/msal-browser';
import {
    MSAL_GUARD_CONFIG,
    MSAL_INSTANCE,
    MSAL_INTERCEPTOR_CONFIG,
    MsalBroadcastService,
    MsalGuard,
    MsalGuardConfiguration,
    MsalInterceptor,
    MsalInterceptorConfiguration,
    MsalModule,
    MsalService,
    ProtectedResourceScopes,
} from '@azure/msal-angular';
import { apiConfig, msalConfig } from '../../auth-config';
import { TopBarComponent } from './components/top-bar/top-bar.component';
import { ReactiveFormsModule } from '@angular/forms';
import { MatButton } from '@angular/material/button';
import { FormatFullDate } from './pipes/format-data.pipe';
import { MatFormField, MatFormFieldModule } from '@angular/material/form-field';
import { MatChip, MatChipGrid, MatChipInput, MatChipRow } from '@angular/material/chips';
import { MatIcon } from '@angular/material/icon';
import { MatDatepicker, MatDatepickerInput, MatDatepickerToggle } from '@angular/material/datepicker';
import { MAT_DATE_LOCALE, MatNativeDateModule } from '@angular/material/core';
import { MatInputModule } from '@angular/material/input';
import { ExtractorFormComponent } from './components/extractor-form/extractor-form.component';
import { NotificationPanelComponent } from './components/notification-panel/notification-panel.component';
import { LocalInterceptor } from './interceptors/local.interceptor';
import { provideMomentDateAdapter } from '@angular/material-moment-adapter';
import { LoaderComponent } from './components/loader/loader.component';

export function MSALInstanceFactory(): IPublicClientApplication {
    return new PublicClientApplication(msalConfig);
}

export function MSALGuardConfigFactory(): MsalGuardConfiguration {
    return {
        interactionType: InteractionType.Redirect,
        loginFailedRoute: window.location.origin,
    };
}

export const protectedResources = {
    MainStreetContact: {
        endpoint: environment.backendApi + '/api/*',
        scopes: apiConfig.b2cScopes,
    },
};

export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
    const protectedResourceMap = new Map<string, (string | ProtectedResourceScopes)[]>([
        [protectedResources.MainStreetContact.endpoint, protectedResources.MainStreetContact.scopes],
    ]);
    return {
        interactionType: InteractionType.Redirect,
        protectedResourceMap,
    };
}

@NgModule({
    declarations: [AppComponent],
    imports: [
        // Angular
        BrowserModule,
        AppRoutingModule,
        BrowserAnimationsModule,
        HttpClientModule,
        // Authentication
        MsalModule,
        QtmComponentLibraryModule,
        TopBarComponent,
        ReactiveFormsModule,
        MatButton,
        FormatFullDate,
        MatFormField,
        MatChip,
        MatIcon,
        MatChipInput,
        MatChipGrid,
        MatChipRow,
        MatDatepickerToggle,
        MatDatepickerInput,
        MatDatepicker,
        MatNativeDateModule,
        MatFormFieldModule,
        MatInputModule,
        ExtractorFormComponent,
        NotificationPanelComponent,
        LoaderComponent,
    ],
    providers: [
        {
            provide: MSAL_INSTANCE,
            useFactory: MSALInstanceFactory,
        },
        {
            provide: MSAL_GUARD_CONFIG,
            useFactory: MSALGuardConfigFactory,
        },
        {
            provide: MSAL_INTERCEPTOR_CONFIG,
            useFactory: MSALInterceptorConfigFactory,
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: MsalInterceptor,
            multi: true,
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: LocalInterceptor,
            multi: true,
        },
        MsalService,
        MsalGuard,
        MsalBroadcastService,
        provideMomentDateAdapter({
            parse: {
                dateInput: 'DD/MM/YYYY',
            },
            display: {
                dateInput: 'DD/MM/YYYY',
                monthYearLabel: 'MMM YYYY',
                dateA11yLabel: 'DD/MM/YYYY',
                monthYearA11yLabel: 'MMM YYYY',
            },
        }),
        { provide: MAT_DATE_LOCALE, useValue: 'en-US' },
    ],
    bootstrap: [AppComponent],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule {}
