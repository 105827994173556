import { Pipe, PipeTransform } from '@angular/core';

/*
   FORMAT DATE AND TIME
*/

// return format : Day of week, Month day, year (ex : Wednesday, May 10, 2023)
@Pipe({ standalone: true, name: 'formatFullDate' })
export class FormatFullDate implements PipeTransform {
    transform(value: Date): string | undefined {
        if (value) {
            return value.toLocaleDateString('en-US', {
                weekday: 'long',
                month: 'short',
                day: 'numeric',
                year: 'numeric',
                timeZone: 'UTC',
            });
        }
        return;
    }
}

// return format : hh:mm:ss (ex: 14:12:39)
@Pipe({ standalone: true, name: 'formatFullTime' })
export class FormatFullTime implements PipeTransform {
    transform(value: Date): string | undefined {
        if (value) {
            return value
                .toLocaleString('en-US', {
                    hour: '2-digit',
                    minute: '2-digit',
                    second: '2-digit',
                    timeZone: 'UTC',
                    hourCycle: 'h23',
                })
                .toUpperCase();
        }
        return;
    }
}

@Pipe({ standalone: true, name: 'formatDate' })
export class FormatDate implements PipeTransform {
    transform(value: string | Date | null, returnFormat: string = 'mm/dd/yyyy'): string | undefined {
        if (!value) {
            return;
        }
        const date = new Date(value);
        const day = date.getDate();
        const dd = day < 10 ? '0' + day : day;
        const month = date.getMonth() + 1;
        const mm = month < 10 ? '0' + month : month;
        const yyyy = date.getFullYear();

        if (returnFormat === 'yyyy-mm-dd') {
            return yyyy + '-' + mm + '-' + dd;
        }

        if (returnFormat === 'mm/dd/yy') {
            return dd + '/' + mm + '/' + (yyyy % 100);
        }

        return dd + '/' + mm + '/' + yyyy;
    }
}
