<ng-container>
    <top-bar></top-bar>
    <div class="container">
        <div class="content">
            <notification-panel></notification-panel>
            @if (authService.roles.includes("role_extractor")) {
                <div class="header">
                    <qtm-typography fontSize="4" fontWeight="bold"> Welcome to the AVD Extractor Interface!</qtm-typography>
                    <qtm-typography fontSize="7" fontWeight="normal">
                        Fields are cumulative, the more fields you fill in, the more precise the search will be. <br />
                        To be able to submit the form at least one field of the non-required fields must be filled.
                    </qtm-typography>
                </div>
                <extractor-form></extractor-form>
            } @else {
                <div class="header">
                    <qtm-typography fontSize="4" fontWeight="bold"> You do not have sufficient rights to access AVD Extractor!</qtm-typography>
                    <qtm-typography fontSize="7" fontWeight="normal">
                        If you need this access, please contact <i>Flights Footprint</i> team.
                    </qtm-typography>
                </div>
            }
        </div>
        @if (coreService.displayLoader()) {
            <loader></loader>
        }
    </div>
</ng-container>
