<div class="content">
    <div class="left">
        <qtm-typography fontSize="6" fontWeight="bold"> AVD Extractor </qtm-typography>
    </div>

    <div class="right">
        <div class="date">
            <qtm-typography fontSize="7" fontWeight="bold">
                {{ coreService.getCurrentDate() | formatFullDate }}
            </qtm-typography>
            @if (coreService.getCurrentDate()) {
                <qtm-typography fontSize="8" fontWeight="regular" class="time">
                    {{ coreService.getCurrentDate() | formatFullTime }} UTC
                </qtm-typography>
            }
        </div>
        <div class="divider"></div>
        <div class="profile">
            <div class="identity">
                <qtm-typography fontSize="7" fontWeight="bold"> {{ authService.firstName }} {{ authService.lastName }} </qtm-typography>
                <qtm-typography fontSize="8" fontWeight="regular">
                    {{ authService.userMail }}
                </qtm-typography>
            </div>
            <button mat-button (click)="authService.signOut()" class="sign-out">
                <mat-icon class="material-icons-round"> power_settings_new </mat-icon>
            </button>
        </div>
    </div>
</div>
