import { Component, Input } from '@angular/core';
import { MatIcon } from '@angular/material/icon';
import { QtmComponentLibraryModule } from '@qtm/angular';
import { CoreService } from '../../../../services/core/core.service';
import { NgClass } from '@angular/common';
import { Notification } from '../../../../models/notification.interface';

@Component({
    selector: 'pop-in-notification',
    standalone: true,
    imports: [MatIcon, QtmComponentLibraryModule, NgClass],
    templateUrl: './notification.component.html',
    styleUrl: './notification.component.scss',
})
export class NotificationComponent {
    hideWarning = true;

    @Input()
    public notification: Notification | undefined;

    constructor(public coreService: CoreService) {
        setTimeout(() => {
            this.hideWarning = false;
        }, 500);
    }

    closePanel(): void {
        this.hideWarning = true;
    }
}
